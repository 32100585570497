<template>
    <div class="container">
        <div class="list-item-box " v-for="(item,index) of list" :key="index">
            <div class="flex m-b-17">
                <div class="list-item ">
                    <div class="list-item-title">{{item.name}}</div>
                </div>
            </div>

            <div class="flex ">
                <div class="list-item ">
                    <div class="list-item-answer">{{item.answer}}</div>
                </div>
            </div>
        </div>
          <!-- ============分页=============== -->
        <pagination-box :pagination="pagination" @pageChange="pageChange"  style="margin-top:60px"></pagination-box>
    </div>
</template>
<script>
import { Indicator } from "mint-ui";
var util = require("@/utils/util");
import paginationBox from '@/components/common/pagination.vue'; 
export default {
    data(){
        return {
            list : [],
            pagination: {}, //分页数据
            page:1
        }
    },
    components: {
		paginationBox,
	},
    async  mounted() {
        Indicator.open(this.$t('tip.loading'));
        await this.commonGetList('os_faq');
        Indicator.close()
    },
    methods : {
        commonGetList(table){
            const ts = this;
            return new Promise( ( resolve ) => {
                util.requests('post',{
                    url: "os/commonGetList",
                    data : { table, page_size : 10 , page : ts.page}
                }).then( (res) => {
                    if ( res.code ) {
                        ts.list = res.data.list
                         ts.pagination = {
                            page: res.data.current_page,
                            pagecount: res.data.last_page,
                            total: res.data.total,
                            pagesize: res.data.per_page
                        }
                    }
                    resolve()
                } ).finally( () => {
                     resolve()
                } )
            } )
        },
                          // 分页操作
         pageChange: async function(page) {
            this.page = page
              Indicator.open(this.$t('tip.loading'));
                await this.commonGetList('os_faq');
                Indicator.close()
            },
    }
    
}
</script>
<style lang="scss" scoped>
    .container {
        // margin-top: 52px;
        margin-bottom: 30px;
        .list-item-box {
            border-bottom: 1px solid #E1D2B6;
            padding: 30px 15px;
            position: relative;
            .list-item {
                flex: 1;
                .list-item-title {
                    font-size: 24px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: bold;
                    color: #333;
                    margin-bottom: 8px;
                }
                .list-item-answer {
                 font-size: 18px;
                 font-family: PingFang SC-Regular, PingFang SC;
                 font-weight: 400;
                 color: #666;
                }
            }
        }
    }
    .m-b-17 {
        margin-bottom: 17px;
    }
</style>