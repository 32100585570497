<!-- 文章外布局 -->
<template>
    <section class="flex main">
        <aside>
            <div class="nav-item active">景区介绍</div>
            <div class="nav-item">景区介绍</div>
            <div class="nav-item">景区介绍</div>
            <div class="nav-item">景区介绍</div>
        </aside>
        <section>
            <div class="article-title fwb">景区介绍</div>
            <article>
                <!-- <detail-info></detail-info> -->
                <article-list></article-list>
            </article>
        </section>
    </section>
</template>
<script>
var util = require("@/utils/util");
import DetailInfo from '@/components/DetailInfo.vue';
import ArticleList from './ArticleList.vue';
export default {
    name: "article-layout",
    components: {
        ArticleList
    },
    props: {

    },
    computed: {
    },
    data() {
        return {
        }
    },
}
</script>