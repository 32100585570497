<!-- 顶部banner -->
<template>
    <div class="detail-info">
        <div class="title">
            <div class="flex jc ac">
                <img src="@/assets/body/slide.png" alt="" class="slide" />
                <div class="font_size">{{ title }}</div>
                <img src="@/assets/body/slide.png" alt="" class="slide rotate_180" />
            </div>
        </div>
        <div style="overflow:auto">
            <div class="content-f" v-html="content"></div>
            <div
            v-if="img"
            class="img-box"
            :style="{ backgroundImage : 'url(' + img +')' }">
            </div>
        </div>
    </div>
</template>
<script>
var util = require("@/utils/util");

export default {
    name: "detail-info",
    components: {
    },
    props: {
        htmlData : String | Object,
        title :String,
    },
    computed: {
    },
    data() {
        return {
            content: "",
            img: "",
        }
    },
    methods: {
    },
    watch: {
        htmlData () {
            this.content = this.htmlData instanceof Object ? 
                this.htmlData.content : this.htmlData
            this.img = this.htmlData instanceof Object ? 
                this.htmlData.domain_image : null
        }
    },
}
</script>
<style scoped>
    .section {
        margin-left: 0 !important;
    }
    .detail-info >>> img {
        max-width: 1000px !important;
    }
    .title {
        font-size: 28px;
        font-weight: bold;
        color: #894824;
        margin-top: 37px;
    }
    .content-f {
        color: #333333;
        font-size: 24px;
        margin-top: 37px;
    }
    .img-box {
        width: 842px;
        height: 455px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 4px;
        margin: 72px auto 0;
    }
</style>